import * as React from "react"
import { Container,Box, Grid, Typography, makeStyles} from "@material-ui/core"
import Seo from "../../components/seo"
import Menu from "../../components/festival/menu"
import LineupTitle from "../../components/festival/lineupTitle"
import LineupItem from "../../components/festival/lineupItem"
import LineupDetails from "../../components/festival/lineupDetails"

const useStyles = makeStyles(theme => ({
  
  title: {
    marginBottom: theme.spacing(4)
  },
  text: {
    marginBottom: theme.spacing(4)
  }
}));


const LineupPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
    <Seo title="Tomatostories - Lineup" />
      <Box>
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Menu/>
          </Grid>
          <Grid item xs={12} className={classes.text}>
            <Typography variant="body2" color="primary">
            De line-up van TOMATO FESTIVAL hebben we zo divers mogelijk gemaakt. 
            <br/>
            Allereerst komen op woensdag drie jonge maar geniale bands aan bod! Kom eens langs en ontdek de Balthazar’s & Coely’s van morgen.
            <br/>
            De bands op donderdag zijn al wat ervarener, maar daarom zeker niet minder hip! 
            <br/>
            Vrijdagnamiddag zetten we in op kinderen, want ook voor hen is muziek vormend en belangrijk. 
            <br/>
            En tot slot wordt het stomend heet op vrijdagavond met twee knallers van bands!

            </Typography>
          </Grid>
        </Grid> 
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={4}>
                  <LineupTitle className={classes.title} title="WOENSDAG 18/08"/>
                  <br/>
                  <LineupItem time="18u" text="Doors"/>
                  <br/>
                  <LineupItem time="19u" text="Galine"/>
                  <LineupItem time="20u30" text="Elefan"/>
                  <LineupItem time="22u" text="Shore Shot"/>
                  <br/>
                  <LineupItem time="23u30" text="last call"/>
                  <br/>
                  <LineupDetails link="/festival/lineup/woensdag"/>
          </Grid>
          <Grid item xs={12} md={4}>
              <LineupTitle className={classes.title} title="DONDERDAG 19/08"/>
              <br/>
              <LineupItem time="18u" text="Doors"/>
                  <br/>
                  <LineupItem time="19u" text="momoyo"/>
                  <LineupItem time="20u30" text="School is Cool"/>
                  <br/>
                  <LineupItem time="23u30" text="last call"/>
                  <br/>
                  <LineupDetails link="/festival/lineup/donderdag"/>
            </Grid>
            <Grid item xs={12} md={4}>
            <LineupTitle className={classes.title} title="VRIJDAG 20/08"/>
                  <Typography variant="body2" color="primary">
                            NAMIDDAG
                    </Typography>
                    <br/>
                    <br/>
                  <LineupItem time="13u" text="Doors"/>
                  <br/>
                  <LineupItem time="14u" text="Kapitein Winokio"/>
                  <br/>
                  <LineupItem time="15u30" text="last call"/>
                  <br/>
                  <LineupDetails link="/festival/lineup/vrijdagmiddag"/>

            <br/>
              <LineupTitle className={classes.title} title="VRIJDAG 20/08"/>
                    <Typography variant="body2" color="primary">
                              AVOND
                      </Typography>
                  <br/>
                  <br/>
                  <LineupItem time="18u" text="Doors"/>
                  <br/>
                  <LineupItem time="19u" text="High Hi"/>
                  <br/>
                  <LineupItem time="21u" text="Sylvie Kreusch"/>
                  <br/>
                  <LineupItem time="23u30" text="last call"/>
                  <br/>
                  <LineupDetails link="/festival/lineup/vrijdagavond"/>

            </Grid>
        </Grid>
      </Box>
    </Container>

)}

export default LineupPage
