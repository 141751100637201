import * as React from "react"
import {makeStyles, Box, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from "gatsby";

const useStyles = makeStyles(theme => ({
        box: {
            border: "2px solid",
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            width: "200px",
            textAlign: "center",
            marginLeft: theme.spacing(8),
            "&:hover": {
                cursor: "pointer",
                borderColor: theme.palette.primary.main,
            },
            marginBottom: theme.spacing(2)
        },
        details:{
            color: theme.palette.primary.main,
            textDecoration: "none",
            "&:hover": {
                color: theme.palette.secondary.main,
            },
        }
  }));


const LineupDetails = ({link}) => {
    const classes = useStyles();
    return (
            <Box className={classes.box}>
                <Link to={link}  className={classes.details}>
                    <Typography variant="h6">DETAILS</Typography>
                </Link>
            </Box>
    )
}

LineupDetails.propTypes = {
    link: PropTypes.string.isRequired
}


export default LineupDetails