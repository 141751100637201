import * as React from "react"
import {Grid, makeStyles, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
        time: {
            paddingLeft: theme.spacing(8)
        }
  }));


const LineupItem = ({time, text}) => {
    const classes = useStyles();
    return (
            <Grid container>
                <Grid item xs={6} className={classes.time}>
                    <Typography variant="body2" color="primary">
                        {time}
                    </Typography>
                </Grid>
                <Grid item xs={6}>                
                    <Typography variant="body2" color="primary">
                            {text}
                    </Typography>
                </Grid>
            </Grid>
    )
}

LineupItem.propTypes = {
    time: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default LineupItem